.organizations__form .organizations__form-group {
  width: 100%;
  margin: 0;
}

.organizations__form .organizations__form-group .btn-filter-select {
  height: 26px !important;
  padding: 2px 12px 6px 12px !important;
}

.organizations__form .organizations__form-group_horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.organizations__form .organization__form-secondary_filter {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
      -ms-flex: auto;
          flex: auto;
  margin-right: auto;
  padding-right: 20px;
}

.organizations__form .organizations__form-group_button-section {
  padding-top: 10px;
}

.organizations__form .organizations__form-group_button-cart-section {
  padding-top: 20px;
}

.organizations__form .organizations__form-group_center {
  text-align: center;
}

.organizations__form label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #1D1D1F;
  margin-bottom: 5px;
}

.organizations__form input, .organizations__form select, .organizations__form .selectpicker {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
      -ms-flex: auto;
          flex: auto;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 40px;
}

.organizations__form input:hover, .organizations__form input:focus, .organizations__form select:hover, .organizations__form select:focus, .organizations__form .selectpicker:hover, .organizations__form .selectpicker:focus {
  box-shadow: 0px 5px 15px rgba(75, 73, 177, 0.2);
  border: 1px solid #DDDDDD;
}

.organizations__form .organizations__button {
  background: #4B49B1;
  border-radius: 10px;
  padding: 10px 20px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  height: 40px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.organizations__form .organizations__button_white {
  background: #FFFFFF;
  color: #000000;
}

.organizations__form .organizations__button_with-border {
  border: 1px #000000 solid;
}

.organizations__form .organizations__button_with-badge {
  position: relative;
}

.organizations__form .organizations__button-badge {
  position: absolute;
  background-color: #4B49B1;
  color: #ffffff;
  font-size: 12px;
  font-weight: bolder;
  height: 21px;
  right: -9px;
  line-height: 20px;
  min-width: 21px;
  text-align: center;
  vertical-align: top;
  top: -9px;
  border-radius: 20px;
  padding: 0px 5px;
  border: 2px solid white;
}

.organizations__form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url("~@img/dropdown/dropdownarrow.png");
  background-repeat: no-repeat;
  background-position: right 16px top 18px;
}

.ranking-card {
  min-height: 245px;
  background: white;
  position: relative;
  border-radius: 15px;
  border: 1px solid #fdfeff;
  box-shadow: 1px 2px 3px #e0e0e0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
}

.ranking-card .ranking-card__title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  color: #646464;
  z-index: 2;
  width: 150px;
  padding-left: 20px;
}

.ranking-card .ranking-card__colors {
  height: 215px;
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.subject-nar-btn {
  cursor: pointer;
  height: 150px;
  background: linear-gradient(95.83deg, #822DB1 7.33%, #4B49B1 51.33%);
  background-size: cover;
  box-shadow: -3px 0px 7px rgba(0, 0, 0, 0.06), 3px 0px 7px rgba(0, 0, 0, 0.06), 0px 7.76218px 6.20974px rgba(111, 114, 132, 0.0605839), 0px 3.23002px 2.58402px rgba(111, 114, 132, 0.0421718);
  border-radius: 15px;
  padding: 7px;
}

.subject-nar-btn .subject-nar-btn__inner {
  position: relative;
  overflow: hidden;
  background: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 100%;
  border-radius: 7px;
  padding: 7px;
}

@media only screen and (max-width: 1199.98px) {
  .subject-nar-btn .subject-nar-btn__inner {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.subject-nar-btn:hover {
  box-shadow: 1px 1px 5px #1a3a4b;
  text-decoration: none;
}

.subject-nar-btn .subject-nar-btn__text {
  padding-left: 160px;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 28px;
  z-index: 2;
  color: #646464;
}

@media only screen and (max-width: 1199.98px) {
  .subject-nar-btn .subject-nar-btn__text {
    padding-left: 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 767.98px) {
  .subject-nar-btn .subject-nar-btn__text {
    font-size: 32px;
  }
}

.subject-nar-btn .subject-nar-btn__img {
  position: absolute;
  width: 35%;
  left: 7px;
}

@media only screen and (max-width: 1199.98px) {
  .subject-nar-btn .subject-nar-btn__img {
    display: none;
  }
}

.russian-step-btn {
  cursor: pointer;
  height: 150px;
  background: linear-gradient(95.83deg, #8188C0, #2B3595);
  background-size: cover;
  box-shadow: -3px 0px 7px rgba(0, 0, 0, 0.06), 3px 0px 7px rgba(0, 0, 0, 0.06), 0px 7.76218px 6.20974px rgba(111, 114, 132, 0.0605839), 0px 3.23002px 2.58402px rgba(111, 114, 132, 0.0421718);
  border-radius: 15px;
}

.russian-step-btn .russian-step-btn__inner {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 100%;
  border-radius: 7px;
  padding: 15px 50px 15px 15px;
}

@media only screen and (max-width: 1199.98px) {
  .russian-step-btn .russian-step-btn__inner {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 15px 15px 15px 15px;
  }
}

.russian-step-btn:hover {
  box-shadow: 1px 1px 5px #1a3a4b;
  text-decoration: none;
}

.russian-step-btn .russian-step-btn__text {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  z-index: 2;
  width: 300px;
  color: #FFFFFF;
}

@media only screen and (max-width: 1199.98px) {
  .russian-step-btn .russian-step-btn__text {
    width: 100%;
    text-align: center;
  }
}

.russian-step-btn .russian-step-btn__img {
  max-width: 147px;
  position: absolute;
  left: 0px;
  top: 0px;
}

@media only screen and (max-width: 1199.98px) {
  .russian-step-btn .russian-step-btn__img {
    display: none;
  }
}

.first-screen-wrapper .tiles-panel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.first-screen-wrapper .tiles-panel .tiles-panel__tiles-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile_highlight {
  background: -webkit-gradient(linear, right top, left top, from(#fff), color-stop(#e6ebff), color-stop(#fff), color-stop(#e6ebff), color-stop(#fff), color-stop(#e6ebff), to(#fff));
  background: linear-gradient(270deg, #fff, #e6ebff, #fff, #e6ebff, #fff, #e6ebff, #fff);
  background-size: 400% 400%;
  -webkit-animation: gradient 5s ease infinite;
          animation: gradient 5s ease infinite;
  border: 1px solid #60599c;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile_default {
  background: #E9EDFB;
  border: 1px solid #E9EDFB;
}

.first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile {
  border-radius: 5px;
}

.first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile:hover {
  border-color: #DDDDDD;
  cursor: pointer;
}

.first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__link {
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  color: #4B49B1;
}

.first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__description {
  font-style: normal;
  font-weight: 500;
  color: #000000;
}

.first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.first-screen-wrapper .greeting-block {
  overflow: hidden;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(3, 52, 121, 0)), color-stop(87.97%, #03337A)) repeat, -webkit-gradient(linear, left top, left bottom, from(#012C6E), to(#023B8C)) repeat;
  background: linear-gradient(180deg, rgba(3, 52, 121, 0) 0%, #03337A 87.97%) repeat, linear-gradient(180deg, #012C6E 0%, #023B8C 100%) repeat;
  background-size: cover;
}

.first-screen-wrapper .greeting-block .greeting-block__inner {
  position: relative;
}

.first-screen-wrapper .greeting-block .greeting-block__image {
  position: absolute;
  right: 0;
  top: 10px;
  overflow: hidden;
}

.first-screen-wrapper .greeting-block .greeting-block__header {
  color: #FFF;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 21px;
}

.first-screen-wrapper .greeting-block .greeting-block__text {
  color: #FFF;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.first-screen-wrapper .greeting-block .greeting-block__text_normal-top-margin {
  margin: 10px 0 0 0;
}

.first-screen-wrapper .greeting-block .greeting-block__text_other-top-margin {
  margin: 10px 0 0 0;
}

.first-screen-wrapper .nav-tabs {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  background: #FFFFFF;
  border: none;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.first-screen-wrapper .nav-tabs li {
  width: 50%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}

.first-screen-wrapper .nav-tabs li .nav-tabs__link {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  color: #000;
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.first-screen-wrapper .nav-tabs li .nav-tabs__link_left {
  border-radius: 15px 0 0 0;
  border-width: 2px 0 0 2px;
  box-shadow: inset 1px 1px 2px #DDDDDD;
}

.first-screen-wrapper .nav-tabs li .nav-tabs__link_right {
  border-radius: 0 15px 0 0;
  border-width: 2px 2px 0 0;
  box-shadow: inset -1px 1px 2px #DDDDDD;
}

.first-screen-wrapper .nav-tabs li .nav-tabs__title {
  line-height: 45px;
}

.first-screen-wrapper .nav-tabs li .nav-tabs__description {
  font-size: 16px;
  line-height: 19px;
  max-width: 232px;
}

.first-screen-wrapper .nav-tabs li .nav-tabs__icon {
  margin-right: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.first-screen-wrapper .nav-tabs li .nav-tabs__icon img {
  width: 100%;
}

.first-screen-wrapper .nav-tabs li.active .nav-tabs__link {
  border: none;
  color: #fff;
  box-shadow: none;
}

.first-screen-wrapper .nav-tabs li.active .nav-tabs__link_left {
  background: -webkit-gradient(linear, right top, left top, color-stop(38.02%, #4B49B1), to(#822DB1));
  background: linear-gradient(270deg, #4B49B1 38.02%, #822DB1 100%);
}

.first-screen-wrapper .nav-tabs li.active .nav-tabs__link_right {
  background: -webkit-gradient(linear, left top, right top, color-stop(38.02%, #4B49B1), to(#822DB1));
  background: linear-gradient(90deg, #4B49B1 38.02%, #822DB1 100%);
}

.first-screen-wrapper .nav-tabs li.active .nav-tabs__icon img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

@media only screen and (min-width: 320px) {
  .first-screen-wrapper .greeting-block .greeting-block__inner {
    padding: 58px 0 68px 0;
  }
  .first-screen-wrapper .greeting-block .greeting-block__image {
    display: none;
    height: 100%;
  }
  .first-screen-wrapper .greeting-block .greeting-block__image img {
    width: 100%;
  }
  .first-screen-wrapper .greeting-block .greeting-block__header {
    max-width: 100%;
  }
  .first-screen-wrapper .greeting-block .greeting-block__text {
    max-width: 100%;
  }
  .mobile-hidden, .mobile-hidden-flex {
    display: none;
  }
  .tablet-hidden, .tablet-hidden-flex {
    display: none;
  }
  .tiles-panel {
    margin-top: 10px;
    gap: 10px;
  }
  .tiles-panel .tiles-panel__tiles-row {
    gap: 10px;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile {
    margin: 0;
    padding: 10px 15px;
    min-width: 100%;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__link {
    font-size: 16px;
    line-height: 14px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    gap: 10px;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__title .tile__description {
    display: block;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__description {
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
    display: none;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__icon {
    margin: 0 0 0 auto;
    max-width: 70px;
  }
  .nav-tabs li .nav-tabs__link {
    padding: 8px 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .nav-tabs li .nav-tabs__title {
    font-size: 20px;
  }
  .nav-tabs li .nav-tabs__icon img {
    height: 40px;
  }
}

@media only screen and (min-width: 400px) {
  .first-screen-wrapper .greeting-block .greeting-block__image {
    display: block;
    height: 100%;
    width: 202px;
    right: -60px;
  }
  .first-screen-wrapper .greeting-block .greeting-block__header {
    max-width: 200px;
  }
  .first-screen-wrapper .greeting-block .greeting-block__text {
    max-width: 249px;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-hidden, .mobile-hidden-flex {
    display: none;
  }
  .first-screen-wrapper .greeting-block .greeting-block__image {
    width: 196px;
    right: 0;
  }
  .first-screen-wrapper .greeting-block .greeting-block__header {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }
  .first-screen-wrapper .greeting-block .greeting-block__text {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }
}

@media only screen and (min-width: 992px) {
  .first-screen-wrapper .greeting-block .greeting-block__inner {
    padding: 80px 0;
  }
  .first-screen-wrapper .greeting-block .greeting-block__image {
    width: 245px;
    right: 0;
  }
  .first-screen-wrapper .greeting-block .greeting-block__header {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .first-screen-wrapper .greeting-block .greeting-block__text {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .tablet-hidden {
    display: block;
  }
  .tablet-hidden-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .tiles-panel {
    margin-top: 20px;
    gap: 20px;
  }
  .tiles-panel .tiles-panel__tiles-row {
    gap: 20px;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile {
    padding: 20px 14px;
    min-width: -webkit-calc((100% - 40px) / 3);
    min-width: calc((100% - 40px) / 3);
    width: -webkit-calc((100% - 40px) / 3);
    width: calc((100% - 40px) / 3);
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__link {
    font-size: 16px;
    line-height: 18px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    gap: 0;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__title {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__title .tile__description {
    display: none;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__description {
    display: block;
    margin-top: 20px;
    width: 100%;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__icon {
    margin: 0 20px 0 0;
    max-width: 65px;
  }
  .nav-tabs li .nav-tabs__link {
    padding: 13px 55px 13px 0;
  }
  .nav-tabs li .nav-tabs__title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1560px) {
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__title {
    width: auto;
  }
  .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__title .tile__description {
    display: none;
  }
}

@media only screen and (min-width: 1920px) {
  .first-screen-wrapper .greeting-block .greeting-block__inner {
    padding: 93px 0;
  }
  .first-screen-wrapper .greeting-block .greeting-block__image {
    width: 321px;
  }
  .first-screen-wrapper .greeting-block .greeting-block__header {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .first-screen-wrapper .greeting-block .greeting-block__text {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .first-screen-wrapper .tiles-panel {
    gap: 20px;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile {
    padding: 43px 42px;
    min-width: -webkit-calc((50% - 30px) / 2);
    min-width: calc((50% - 30px) / 2);
    width: -webkit-calc((50% - 30px) / 2);
    width: calc((50% - 30px) / 2);
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__link {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__title {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__title .tile__description {
    display: none;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__description {
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    margin-top: 20px;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile .tile__icon {
    max-width: 80px;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile_last {
    margin: 0;
    padding: 43px 30px !important;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile_quarter {
    padding: 43px 217px !important;
    min-width: -webkit-calc(50% - 10px) !important;
    min-width: calc(50% - 10px) !important;
    width: -webkit-calc(50% - 10px) !important;
    width: calc(50% - 10px) !important;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile_quarter .tile__link {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile_quarter .tile__title {
    width: auto;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile_quarter .tile__title .tile__description {
    display: none;
  }
  .first-screen-wrapper .tiles-panel .tiles-panel__tiles-row .tiles-row__tile_other {
    padding: 43px 133px !important;
  }
}

.reviews-block-wrapper {
  background-color: #F4F5F6;
  padding: 20px 0;
}

.reviews-block-wrapper .review-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  padding: 0 50px;
}

@media only screen and (max-width: 991.98px) {
  .reviews-block-wrapper .review-item {
    padding: 0 15px;
  }
}

.reviews-block-wrapper .review-item .hidden-text {
  display: none;
}

.reviews-block-wrapper .review-item .review-item-reviewer-photo {
  width: 175px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 175px;
      -ms-flex: 0 0 175px;
          flex: 0 0 175px;
}

@media only screen and (max-width: 991.98px) {
  .reviews-block-wrapper .review-item .review-item-reviewer-photo {
    display: none;
  }
}

.reviews-block-wrapper .review-item .review-item-body {
  -webkit-flex-basis: 700px;
      -ms-flex-preferred-size: 700px;
          flex-basis: 700px;
  min-height: 300px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 991.98px) {
  .reviews-block-wrapper .review-item .review-item-body {
    -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
    min-height: 250px;
  }
}

.reviews-block-wrapper .review-item .review-item-body .review-item-text {
  padding-bottom: 20px;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  color: #1D1D1F;
}

@media only screen and (max-width: 991.98px) {
  .reviews-block-wrapper .review-item .review-item-body .review-item-text {
    font-size: 16px;
  }
}

.reviews-block-wrapper .review-item .review-item-body .review-item-reviewer-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 23px;
}

@media only screen and (max-width: 991.98px) {
  .reviews-block-wrapper .review-item .review-item-body .review-item-reviewer-wrapper {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1;
    padding-bottom: 20px;
  }
}

.reviews-block-wrapper .review-item .review-item-body .review-item-reviewer-mobile-photo {
  -webkit-flex-basis: 20%;
      -ms-flex-preferred-size: 20%;
          flex-basis: 20%;
  margin-right: 20px;
}

@media screen and (max-width: 500px) {
  .reviews-block-wrapper .review-item .review-item-body .review-item-reviewer-mobile-photo {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 350px;
        -ms-flex: 1 1 350px;
            flex: 1 1 350px;
  }
}

@media only screen and (min-width: 992px) {
  .reviews-block-wrapper .review-item .review-item-body .review-item-reviewer-mobile-photo {
    display: none;
  }
}

.reviews-block-wrapper .review-item .toggle-full-review {
  padding: 0;
}

.about-block-wrapper {
  padding: 20px 0;
  text-align: justify;
}

.about-block-wrapper .about-title {
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
  color: #787878;
  padding-bottom: 20px;
}

.about-block-wrapper img {
  border-radius: 15px;
}

.about-block-wrapper p {
  font-size: 18px;
  line-height: 23px;
  color: #1D1D1F;
  padding-bottom: 10px;
}

.about-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.about-block .about-block__link {
  color: #4B49B1;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  font-weight: 500;
  margin: 0 0 0 0;
  cursor: pointer;
}

.about-block .about-block__link:hover {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
}

.about-block .about-block__link img {
  max-width: 120px;
}

.about-block__right-float {
  -webkit-box-pack: end !important;
  -webkit-justify-content: end !important;
      -ms-flex-pack: end !important;
          justify-content: end !important;
}

.news {
  margin-top: 11px;
}

.news p {
  font-size: 18px;
  line-height: 23px;
  color: #1D1D1F;
}

.news a {
  text-decoration: none;
  color: #1D1D1F;
  font-weight: bold;
}

.news a:hover {
  color: #492d71;
}

.news .news__title {
  font-family: 'Raleway', 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 14px;
  font-weight: 600;
  margin: 20px 0 25px 0;
}

.news .news__title .title__link {
  color: #4B49B1;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  float: right;
}

.news .news__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 20px 0 0 0;
}

.news .news__item .item__first {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  overflow: auto;
  -webkit-box-flex: 1.8;
  -webkit-flex: 1.8;
      -ms-flex: 1.8;
          flex: 1.8;
  margin: 0 0 31px 0;
}

.news .news__item .item__first .first__image-wrapper {
  position: relative;
}

.news .news__item .item__first .first__image-wrapper .image-wrapper__image {
  height: auto;
  max-width: 100%;
}

.news .news__item .item__first .first__title-wrapper {
  margin-top: 10px;
  margin-bottom: 0;
}

.news .news__item .item__first .first__title-wrapper:hover {
  color: #492d71;
}

.news .news__item .item__first .first__title-wrapper .title-wrapper__title {
  font-family: Raleway;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
}

.news .news__item .item__first .first__description {
  font-family: Raleway;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  margin-top: 5px;
}

@supports (display: flex) {
  @media only screen and (max-width: 35em) {
    .news .news__item .item__first {
      -webkit-box-flex: 1 !important;
      -webkit-flex: auto !important;
          -ms-flex: auto !important;
              flex: auto !important;
      min-width: auto !important;
    }
  }
}

.news .news__item .item__other {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1.2;
  -webkit-flex: 1.2;
      -ms-flex: 1.2;
          flex: 1.2;
}

.news .news__item .item__other p {
  padding-bottom: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.news .news__item .item__other .other__title {
  font-family: Raleway;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  margin: 11px 0 0 0;
}

.news .news__item .item__other .other__title:hover {
  color: #492d71;
}

.news .news__item .item__other .other__description {
  font-family: Raleway;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  margin: 5px 0 0 0;
}

.news .news__item .item__other .other__created {
  font-family: Raleway;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  margin: 5px 0 0 0;
  color: #bbbbbb;
}

.news .news__item .item__other .other__link-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}

.news .news__item .item__other .other__link-wrapper .link-wrapper__link {
  color: white;
  display: inline-block;
  background: #132935;
  border-radius: 15px;
  font-size: 18px;
  padding: 5.6px 49px;
  margin: 0 0 20px 0;
  font-weight: normal;
}

.news .news__item .item__other .other__link-wrapper .link-wrapper__link:hover {
  background-color: #492d71;
}

@media only screen and (min-width: 320px) {
  .news .news__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .news .news__item .item__first {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .news .news__item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .news .news__item .item__first {
    width: 45%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .news .news__item .item__other {
    margin-left: 20px;
  }
  .news .item__first {
    width: 42%;
  }
  .news .item__first .first__title-wrapper .title-wrapper__title {
    padding: 0 15px;
  }
  .news .item__first .first__description {
    padding: 0 15px;
  }
}

.webinar-block .webinar-block__inner {
  padding: 30px 0 30px 0;
}

.webinar-block .webinar-block__inner .owl-stage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.webinar-block .webinar-block__inner .owl-stage .owl-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.webinar-block .webinar-block__inner .owl-dots {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.webinar-block .webinar-block__inner .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  background-color: #F8F8F8;
  border-radius: 10em;
  display: inline-block;
  margin: 5px;
  -webkit-transition: background-color .5s ease, -webkit-transform .5s ease;
  transition: background-color .5s ease, -webkit-transform .5s ease;
  transition: background-color .5s ease, transform .5s ease;
  transition: background-color .5s ease, transform .5s ease, -webkit-transform .5s ease;
}

.webinar-block .webinar-block__inner .owl-dots .owl-dot.active {
  background-color: #4B49B1;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.webinar-block .webinar-block__link {
  text-decoration: none;
}

.webinar-block .webinar-block__link:focus {
  text-decoration: none;
}

.webinar-block .webinar-block__text {
  text-align: justify;
  line-height: 23px;
  color: #373636;
}

.webinar-block .webinar-block__video-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 500px;
      -ms-flex: 0 0 500px;
          flex: 0 0 500px;
  width: 500px;
  margin-left: 40px;
}

@media only screen and (max-width: 1199.98px) {
  .webinar-block .webinar-block__video-wrapper {
    margin-left: 0;
    -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
  }
}

@media only screen and (max-width: 1199.98px) {
  .webinar-block .webinar-block__message {
    padding-bottom: 10px;
    margin: 0 10px 0 10px;
  }
}

.webinar-block .webinar-block__image {
  margin-bottom: 10px;
  border-radius: 10px;
}

.webinar-block .webinar-block__image img {
  max-width: 100%;
}

.webinar-block .webinar-block__title {
  margin: 0 0 10px 0;
}

.webinar-block .webinar-block__heading {
  font-size: 18px;
  line-height: 22px;
  color: #787878;
}

.webinar-block .webinar-block__heading:hover {
  color: #492d71;
}

.webinar-block .webinar-block__video {
  width: 100%;
  height: 285px;
  margin: 0 auto;
}

.webinar-block .webinar-block__video-caption {
  text-align: center;
  color: #646464;
}

.carousel-custom-nav {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  z-index: 99;
}

.carousel-custom-nav img {
  max-width: 75%;
}

@media only screen and (max-width: 1199.98px) {
  .carousel-custom-nav img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1199.98px) {
  .carousel-custom-nav {
    top: 45%;
  }
}

.carousel-custom-nav .owl-prev, .carousel-custom-nav .owl-next {
  position: absolute;
  color: inherit;
  background: transparent;
  border: none;
  z-index: 100;
}

.carousel-custom-nav .owl-prev.hover, .carousel-custom-nav .owl-next.hover {
  background: transparent;
}

@media only screen and (max-width: 1199.98px) {
  .carousel-custom-nav .owl-prev img, .carousel-custom-nav .owl-next img {
    height: 30px;
  }
}

.carousel-custom-nav .owl-prev {
  left: 0;
}

.carousel-custom-nav .owl-next {
  right: 0;
}
